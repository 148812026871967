.Footer {
    background-color: #343a40;
    color: white;
    text-align: center;
    padding: 20px 0;
    
}

.Footer a {
 color: white;
 text-decoration: none;
 margin: auto 20px;
}

.Footer a:hover {
    color: greenyellow;
}

.logo {
    height: 50px;
    width: 50px;
}