
/* .HomeBackground {
   
    background: #8E0E00;  
    background: -webkit-linear-gradient(to right, #1F1C18, #8E0E00);  
    background: linear-gradient(to right, #1F1C18, #8E0E00); 
    clip-path: polygon(6% 0, 100% 70%, 100% 87%, 100% 100%, 54% 83%, 0 61%, 0 0);

} */


.HomePage {
    padding-top: 80px;
}

.Logo1 {
    display: inline-block;
    /* padding-left: 15px; */
    height: 20%;
    width: 50%;
    margin-right: 0;
}