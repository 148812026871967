/* .contain {
    height: 200px;
} */

.Testimonials {
    padding-top: 30px;
    height: 100%;
    width: 100%;
    opacity: .4;
    
}

.background {
    /* background-color: #088068; */
    position: relative;
    color: black;
    text-align: center;
}

.centered {
    padding-top: 30px;
    padding-bottom: 30px;
    /* position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); */
    width: 95%;
  }

  @media (min-width: 1000px) {
      .centered {
          font-size: 2.0em;
      }
  }