.Request {
    color: white;
    text-align: center;
    padding-top: 5px;
   
}

.Formdiv {
    z-index: 900;
    background-color: rgba(153, 149, 145, 0.8);
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 25px auto;
    text-align: left;
    /* border: 1px solid #eee; */
    /* box-shadow: 0 2px 3px #ccc ; */
    box-sizing: border-box;
    width: 100%;
    color: black;
    border-radius: 20px;
    box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.5);
}

.Checkmark {
    height: 200px;
    width: 200px;
    text-align: center;
    padding: 10px;
}


