.SocialMediaBar {
    color: white;
    align-content: center;
    text-align: center;
 
}

.SocialMediaBar a {
    text-align: center;
    color: white;
    padding-bottom: 10px;
    margin-bottom: 20px;
}


